.social-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #0A0A0A;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-radius: 10px;
    padding: 2rem;
    flex-wrap: wrap;
}

.icon {
    color: white;
    margin: 15px;
    font-size: 3rem;
    transition: color 0.3s ease;
    padding: 1rem 2rem 1rem;
    box-shadow: 3px 3px 1px 1px rgba(0, 0, 0, 0.3);
}

/* Social icon hover styles */
.twitter:hover {
    color: #1DA1F2;
}

.instagram:hover {
    color: #C13584;
}

.github:hover {
    color: #181717;
}

.discord:hover {
    color: #5865F2;
}

.linkedIn:hover {
    color: #0A66C2;
}

/* Responsive adjustments for mobile */
@media (max-width: 768px) {
    .social-footer {
        justify-content: center;
        padding: 1rem;
        padding-bottom: 1.5rem;
    }

    .icon {
        font-size: 2rem;
        padding: 0.5rem 1rem;
        margin: 5px;
    }
}