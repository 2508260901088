@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');

.discord-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    animation: moveUpAndReveal 1s forwards;
    animation-delay: 3s;
}

.logo-container {
    /* Initial width */
    width: 100px;
    overflow: hidden;
    animation: expandLogo 2s forwards;
}

@keyframes expandLogo {
    0% {
        width: 100px;
    }

    50% {
        width: 500px;
    }

    100% {
        width: 500px;
    }

    /* Adjust final width as needed */
}

.discord-logo {
    /* Adjust logo size as needed */
    transition: transform 2s;
    animation: slideIn 2s forwards;
}

@keyframes slideIn {
    from {
        transform: translateX(-20px);
    }

    to {
        transform: translateX(0);
    }
}

.text-container {
    max-width: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: max-width 2s ease;
    animation: revealText 3s forwards;
}

@keyframes revealText {
    0% {
        max-width: 0;
    }

    50% {
        max-width: 0;
    }

    100% {
        max-width: 50%;
    }

    /* Adjust as needed */
}

p {
    margin: 0;
    padding-left: 20px;
    /* Space between logo and text */
}

.bot-title {
    font-size: 4.5rem;
    position: relative;
    z-index: 1;
    color: rgb(255, 115, 0);
}

.additional-text {
    opacity: 0;
    transform: translateY(20px);
    /* Start slightly below */
    transition: opacity 2s ease, transform 2s ease;
    animation-delay: 3s;
    /* Delay to match the container's animation */
    animation-fill-mode: forwards;
}

@keyframes moveUpAndReveal {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
        /* Move up */
        height: 40vh;
        /* Increase height to reveal more content */
    }
}

@keyframes revealAdditionalText {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.additional-text {
    opacity: 0;
    transform: translateY(20px);
    /* Start slightly below */
    transition: opacity 2s ease, transform 2s ease;
    animation-delay: 5s;
    /* Delay to match the container's animation */
    animation-fill-mode: forwards;
}

.additional-text {
    animation: revealAdditionalText 3s forwards;
    animation-delay: 3s;
}

.bot-feature-title {
    font-size: 2.5rem;
    position: relative;
    color: rgb(255, 115, 0);
    font-weight: bold;
}

.bot-text {
    font-size: 1.7rem;
    position: relative;
    z-index: 1;
    color: white;
    padding-left: 5rem;
}

.bot-two-paragraphs {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

.bot-paragraph {
    margin: 10px;
    flex: 1;
    font-size: 1.5rem;
    position: relative;
    z-index: 1;
    color: rgb(218, 218, 218);
    padding: 2rem;
    border-radius: 5%;
    box-shadow: 2px 2px 2px 2px rgb(255, 115, 0);
    background-color: #131313;
    max-width: 35%;
}

.bot-paragraph ul {
    list-style-type: none;
    /* Hides default bullets */
    padding-left: 0;
    /* Removes default padding */
}

.bot-paragraph li {
    font-size: 1.15rem;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
}

.bot-paragraph li svg {
    margin-right: 10px;
    width: 20px;
    /* Adjust size as needed */
    height: auto;
    color: #4fc3f7;
    /* Choose a brighter color for icons */
}

/*=======================================================*/
/*                     MOBILE                            */
/*                     VERSION                           */
/*=======================================================*/

@media only screen and (max-width: 600px) {
    .discord-container {
        flex-direction: column;
        /* Stack logo and text */
        height: auto;
        /* Adjust height as needed */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo-container {
        width: 100%;
        /* Adjust width as needed */
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .text-container {
        width: 100%;
        /* Adjust max-width as needed */
    }

    .bot-title {
        font-size: 2rem;
        /* Adjust font-size as needed */
    }

    .additional-text {
        font-size: 1.5rem;
        /* Adjust font-size as needed */
    }

    .bot-feature-title {
        font-size: 1.75rem;
        /* Adjust font-size as needed */
    }

    .bot-text {
        font-size: 1.5rem;
        /* Adjust font-size as needed */
    }

    .bot-two-paragraphs {
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        padding: 0;
        /* Stack paragraphs */
    }

    .bot-paragraph {
        max-width: 100%;
        /* Adjust max-width as needed */
    }

    .bot-paragraph li {
        font-size: 1.15rem;
        /* Adjust font-size as needed */
    }
}