.app {
    width: 100%;
    height: 100vh;
    background-color: #0A0A0A;
    color: white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 1s;
}

.hello-text {
    font-size: 5rem;
    position: relative;
    z-index: 1;
    font-family: fam-display, sans-serif;
}


.drop {
    position: absolute;
    top: -50px;
    /* Start above the screen */
    left: calc(50% - 10px);
    width: 20px;
    height: 40px;
    /* Make the drop taller than it is wide */
    background-color: rgb(255, 255, 255);
    border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    /* Elliptical border-radius for droplet shape */
    animation: drop 0.5s linear forwards, wiggle 1s ease-in-out infinite;
}

@keyframes drop {
    to {
        top: calc(50% - 20px);
        /* Adjust as necessary for drop endpoint */
    }
}

@keyframes wiggle {

    0%,
    100% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(10deg);
        /* Adjust for desired wiggle effect */
    }
}



.zoom {
    animation: zoomIn 1s ease forwards;
}

@keyframes zoomIn {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(10);
        opacity: 0;
    }
}

/* Add styles for page reveal and other content */
.page-content {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    color: white;
    /* Or any color you prefer */
    width: 100%;
    height: 100%;
}

.title {
    font-size: 4.5rem;
    position: relative;
    z-index: 1;
    color: blueviolet;
    padding: 0rem 0 0 5rem;
}

.home-text {
    font-size: 2rem;
    position: relative;
    z-index: 1;
    color: white;
    padding-left: 5rem;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}



.languages-list {
    padding: 0 4rem 5rem;
    font-size: 1.5rem;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.language-item {
    background: #242424;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.language-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.language-icon {
    margin-right: 10px;
    color: #007bff;
    /* Icon color */
}

.language-name {
    font-size: 16px;
    font-weight: bold;
}


/*==================== CYBERPUNK BUTTON ===================*/
/*                  Shoutout Steiven Lei                   */
/*              https: //codepen.io/stevenlei              */
/*=========================================================*/

.resume-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.cyberpunk-button,
.cyberpunk-button::after {
    width: 300px;
    font-size: 36px;
    font-family: 'Bebas Neue', cursive;
    background: linear-gradient(45deg, transparent 5%, #6e030c 5%);
    border: 0;
    color: #fff;
    letter-spacing: 3px;
    line-height: 88px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
}

.cyberpunk-button:hover {
    cursor: pointer;
    animation: 1s button-glitch infinite;
}

.cyberpunk-button::after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);

    content: 'RESUME';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #01012B 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
}

.cyberpunk-button:hover::after {
    animation: 1s glitch infinite;
    animation-timing-function: steps(2, end);
}

@keyframes glitch {
    0% {
        clip-path: var(--slice-1);
        transform: translate(-20px, -10px);
    }

    10% {
        clip-path: var(--slice-3);
        transform: translate(10px, 10px);
    }

    20% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 10px);
    }

    30% {
        clip-path: var(--slice-3);
        transform: translate(0px, 5px);
    }

    40% {
        clip-path: var(--slice-2);
        transform: translate(-5px, 0px);
    }

    50% {
        clip-path: var(--slice-3);
        transform: translate(5px, 0px);
    }

    60% {
        clip-path: var(--slice-4);
        transform: translate(5px, 10px);
    }

    70% {
        clip-path: var(--slice-2);
        transform: translate(-10px, 10px);
    }

    80% {
        clip-path: var(--slice-5);
        transform: translate(20px, -10px);
    }

    90% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 0px);
    }

    100% {
        clip-path: var(--slice-1);
        transform: translate(0);
    }
}

@keyframes button-glitch {
    0% {
        clip-path: var(--slice-1);
        transform: translate(-10px, -5px);
    }

    10% {
        clip-path: var(--slice-3);
        transform: translate(5px, 5px);
    }

    20% {
        clip-path: var(--slice-1);
        transform: translate(-5px, 5px);
    }

    30% {
        clip-path: var(--slice-3);
        transform: translate(0px, 5px);
    }

    40% {
        clip-path: var(--slice-2);
        transform: translate(-5px, 0px);
    }

    50% {
        clip-path: var(--slice-3);
        transform: translate(5px, 0px);
    }

    60% {
        clip-path: var(--slice-4);
        transform: translate(5px, 5px);
    }

    70% {
        clip-path: var(--slice-2);
        transform: translate(-5px, 5px);
    }

    80% {
        clip-path: var(--slice-5);
        transform: translate(10px, -5px);
    }

    90% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 0px);
    }

    100% {
        clip-path: var(--slice-1);
        transform: translate(0);
    }
}

/*==================== MOBILE VERSION ===================*/
/*                                                       */
/*                                                       */
/*=======================================================*/

@media screen and (max-width: 768px) {
    .title {
        font-size: 3rem;
        padding: 0 0 0 2rem;
    }

    .home-text {
        font-size: 1.5rem;
        padding-left: 2rem;
    }

    .languages-list {
        padding: 0 2rem 5rem;
        font-size: 1rem;
    }

    .language-item {
        padding: 5px 10px;
    }

    .language-name {
        font-size: 12px;
    }

    .cyberpunk-button:hover {
        cursor: pointer;
        animation: 1s button-glitch infinite;
    }

    .resume-button-container {
        padding: 10%;
    }
}