/* General Header Styles */
header {
    width: 100%;
    padding: 2rem;
}

.header-container {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #0A0A0A;
    transition: background-color 0.3s;
    z-index: 100;
}

.header-container.sticky {
    background-color: rgba(143, 143, 143, 0.2);
}

/* Navbar Styles */
.navbar {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.navbar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar li {
    flex: 1;
    text-align: center;
}

.navbar a {
    text-decoration: none;
    color: #d3d0d0;
    text-transform: uppercase;
    position: relative;
}

.navbar a:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: #ffffff;
    width: 0;
    transition: width 0.5s;
}

.navbar a:hover:after {
    width: 100%;
}

/* Hamburger Menu Styles */
.hamburger {
    display: none;
    /* Hidden by default */
    cursor: pointer;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    position: absolute;
    /* Position the hamburger menu absolutely */
    top: 2rem;
    /* Adjust as needed, should match header's padding for vertical alignment */
    right: 2rem;
    /* Adjust as needed, for right alignment */
    z-index: 110;
}

.hamburger span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: #ffffff;
    transition: all 0.3s linear;
}

/* Hamburger to X Transformation */
.hamburger.open span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open span:nth-child(2) {
    opacity: 0;
}

.hamburger.open span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
}

/* Mobile Navbar Styles */
@media (max-width: 768px) {

    header {
        padding: 0;
    }

    .hamburger {
        display: flex;
        /* Shows on mobile */
    }

    .header-container {
        position: relative;
        padding: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        max-height: 8vh;
    }

    .header-container.navbar-open {
        max-height: 30vh;
    }

    .navbar {
        position: relative;
        left: 0;
        right: 0;
        background-color: #141414;
        transition: top 0.3s;
        z-index: 100;
        width: 100%;
        flex-direction: column;
        padding: 0;
    }

    .navbar ul {
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .navbar li {
        font-size: 1.25rem;
    }

    .navbar.open ul {
        transform: translateY(0);
    }


    .navbar.closed ul {
        animation: slideUp 0.3s forwards;
        visibility: hidden;
    }

    .m .navbar.closed {
        visibility: hidden;
    }


    .navbar li {
        margin: 10px 0;
    }

    @keyframes slideDown {
        0% {
            transform: translateY(-100%);
        }

        100% {
            transform: translateY(0);
        }
    }

    @keyframes slideUp {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(-100%);
        }
    }
}